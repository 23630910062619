import axios from "axios";
import React, { useState } from "react";
import { ReactComponent as Loading } from "./../../assets/loader.svg";
import { ReactComponent as ArrowRight } from "./../../assets/arrow-right.svg";

const StepOne = ({ setVehicleData, toNextStep }) => {
  const [loading, setLoading] = useState(false);
  const [regPlate, setRegPlate] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const domain =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "http://momentum-tuning.local:8888"
        : "";

    const vehicleData = await axios.post(
      `${domain}/wp-json/mt-quotes-api/v1/reg-plate`,
      {
        regPlate,
      }
    );

    setVehicleData({ regPlate, ...vehicleData.data });
    setLoading(false);
    toNextStep();
  };

  return (
    <div className="widget-steps">
      <form
        onSubmit={onSubmit}
        method="post"
        className="widget-enter-reg-number"
      >
        <div className="widget-enter-reg-number-inner">
          <h3>Enter your reg number</h3>

          <input
            type="text"
            className="number-plate-input"
            value={regPlate}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z\d ]/, "");
              setRegPlate(e.target.value);
            }}
            maxLength={10}
          />
        </div>

        <button
          className="next-step-arrow"
          type="submit"
          disabled={!regPlate || loading}
        >
          {loading ? <Loading className="loading-animation" /> : <ArrowRight />}
        </button>
      </form>
    </div>
  );
};

export default StepOne;
