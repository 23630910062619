import React, { useState } from "react";

import Progress from "./includes/Progress";
import EnterRegNumber from "./steps/enter-reg-number/EnterRegNumber";
import VehicleConfirmation from "./steps/vehicle-confirmation/VehicleConfirmation";
import SelectTuningUpgrade from "./steps/select-tuning-upgrade/SelectTuningUpgrade";
import TuningOptions from "./steps/tuning-options/TuningOptions";
import SubmitForm from "./steps/submit-form/SubmitForm";
import Success from "./steps/success/Success";

import "./App.scss";

const App = () => {
  const TOTAL_STEPS = 6;
  const [currentStep, setCurrentStep] = useState(1);
  const [vehicleData, setVehicleData] = useState(false);

  const renderSteps = () => {
    switch (currentStep) {
      case 1:
        return (
          <EnterRegNumber
            setVehicleData={setVehicleData}
            toNextStep={() => setCurrentStep(2)}
          />
        );
      case 2:
        return (
          <VehicleConfirmation
            vehicleData={vehicleData}
            toPreviousStep={() => setCurrentStep(1)}
            toNextStep={() => setCurrentStep(3)}
          />
        );
      case 3:
        return (
          <SelectTuningUpgrade
            vehicleData={vehicleData}
            setVehicleData={setVehicleData}
            toPreviousStep={() => setCurrentStep(2)}
            toNextStep={() => setCurrentStep(4)}
          />
        );
      case 4:
        return (
          <TuningOptions
            vehicleData={vehicleData}
            setVehicleData={setVehicleData}
            toPreviousStep={() => setCurrentStep(3)}
            toNextStep={() => setCurrentStep(5)}
          />
        );
      case 5:
        return (
          <SubmitForm
            vehicleData={vehicleData}
            toPreviousStep={() => setCurrentStep(4)}
            toNextStep={() => setCurrentStep(6)}
          />
        );
      case 6:
        return <Success />;
      default:
    }
  };

  return (
    <div>
      <div className="bhp-calculator-widget">
        <Progress totalSteps={TOTAL_STEPS} currentStep={currentStep} />

        {renderSteps()}
      </div>
    </div>
  );
};

export default App;
