import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-invisible-recaptcha";
import { ReactComponent as ArrowLeft } from "./../../assets/arrow-left.svg";
import { ReactComponent as Loading } from "./../../assets/loader.svg";

const SubmitForm = ({ vehicleData, toPreviousStep, toNextStep }) => {
  const recaptchaRef = useRef();
  const [name, setName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [recaptchaResolved, setRecaptchaResolved] = useState(false);

  // Recaptcha
  const onPreSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);
    recaptchaRef.current.execute();
  };

  const onSubmit = useCallback(async () => {
    try {
      const domain =
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? "http://momentum-tuning.local:8888"
          : "";

      await axios.post(`${domain}/wp-json/mt-quotes-api/v1/create-quote`, {
        name,
        postcode,
        phone,
        email,
        vehicleData,
        recaptcha: recaptchaRef.current.getResponse(),
      });

      toNextStep();
    } catch (e) {
      const message = e.response.data.message;
      setError(message);
      setProcessing(false);
      recaptchaRef.current.reset();
    }
  }, [name, postcode, email, vehicleData, phone, toNextStep]);

  useEffect(() => {
    if (recaptchaResolved) {
      onSubmit();
    }
  }, [onSubmit, recaptchaResolved]);

  return (
    <div className="widget-steps final-step">
      <div className="submit-form">
        {!vehicleData.noTuning && (
          <div className="selected-plan">
            <div className="selected-plan-text">Selected Plan</div>
            <div
              className={`selected-plan-box ${vehicleData.tuningOption.type}`}
            >
              <div className="selected-plan-type">
                <div className="inner">
                  <div></div>
                </div>
              </div>

              <div className="increase-stat-container">
                <div className="increased-perc">
                  +{vehicleData.tuningOption.bhpincrease}%
                </div>
                <small className="increased-label">BHP</small>
              </div>

              <div className="increase-stat-container">
                <div className="increased-perc">
                  +{vehicleData.tuningOption.torqueincrease}%
                </div>
                <small className="increased-label">Torque</small>
              </div>

              <div className="increase-stat-container">
                <div className="increased-perc">
                  {vehicleData.tuningOption.mpg}
                </div>
                <small className="increased-label">MPG</small>
              </div>
            </div>
          </div>
        )}

        {error && <div class="form-error-message">{error}</div>}

        <form onSubmit={onPreSubmit} className="submit-inner-form">
          <div className="form-field">
            <label htmlFor="firstName">Full Name</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              max={200}
            />
          </div>

          <div className="form-field">
            <label htmlFor="lastName">Postcode</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              required
              max={200}
            />
          </div>

          <div className="form-field">
            <label htmlFor="phone">Mobile Number</label>
            <input
              type="phone"
              name="phone"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              max={200}
            />
          </div>

          <div className="form-field">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              max={200}
            />
          </div>

          <div className="form-field">
            <div className="quote-checkbox">
              <input
                type="checkbox"
                id="terms-checkbox"
                checked={termsAgreed}
                onChange={(e) => setTermsAgreed(e.target.checked)}
              />
              <label htmlFor="terms-checkbox">
                <span>
                  I consent to receiving an instant quote for the selected plan.
                  See <a href="/">terms and conditions</a> for more info.
                </span>
              </label>
            </div>
          </div>

          <div className="form-buttons">
            <button
              type="button"
              className="prev-step-arrow"
              onClick={toPreviousStep}
            >
              <ArrowLeft />
            </button>
            <button
              type="submit"
              className="submit-button"
              disabled={!termsAgreed || processing}
            >
              {processing ? (
                <Loading className="loading-animation" />
              ) : (
                "Finish"
              )}
            </button>
          </div>
        </form>

        {/* Recaptcha */}
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={window.WIDGET_OPTIONS.recaptchaSiteKey}
          onResolved={() => setRecaptchaResolved(true)}
        />
      </div>
    </div>
  );
};

export default SubmitForm;
