import React from "react";
import { ReactComponent as Alert } from "./../../assets/alert-triangle.svg";

const VehicleConfirmation = ({ vehicleData, toPreviousStep, toNextStep }) => {
  if (!vehicleData.gcodeid)
    return (
      <div className="widget-steps">
        <div className="vehicle-error">
          <Alert />
          <div>An error occurred</div>

          <div className="vehicle-confirmation-buttons">
            <button
              className="confirmation-button try-again"
              onClick={toPreviousStep}
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <div className="widget-steps">
      <div className="widget-vehicle-confirmation">
        <h4>Is this your vehicle?</h4>
        <h2>{vehicleData.make}</h2>
        <p>
          {vehicleData.model}, {vehicleData.registrationyear.split("-")[0]}
        </p>
        <p>
          {vehicleData.fuel}, {vehicleData.shifts}
        </p>

        <div className="vehicle-confirmation-buttons">
          <button className="confirmation-button confirm" onClick={toNextStep}>
            That's the one!
          </button>
          <button
            className="confirmation-button try-again"
            onClick={toPreviousStep}
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default VehicleConfirmation;
