import React from "react";
import { ReactComponent as Check } from "./../../assets/check-circle.svg";

const Success = () => {
  return (
    <div className="widget-steps">
      <div className="vehicle-success">
        <Check />
        Thank You!
        <br />
        <small>You have received a copy of your enquiry by email</small>
      </div>
    </div>
  );
};

export default Success;
