import React, { useState, useEffect } from "react";
import axios from "axios";
import { ReactComponent as Wrench } from "./../../assets/tool.svg";
import { ReactComponent as ArrowRight } from "./../../assets/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "./../../assets/arrow-left.svg";

const SelectTuningUpgrade = ({
  vehicleData,
  setVehicleData,
  toPreviousStep,
  toNextStep,
}) => {
  const [loading, setLoading] = useState(true);
  const [tuningOptions, setTuningOptions] = useState([]);
  const [optionSelected, setOptionSelected] = useState();
  const [noTuning, setNoTuning] = useState(vehicleData.noTuning);

  useEffect(() => {
    const domain =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "http://momentum-tuning.local:8888"
        : "";

    axios
      .post(`${domain}/wp-json/mt-quotes-api/v1/tuning-options`, {
        gcodeId: vehicleData.gcodeid,
        regPlate: vehicleData.regPlate,
      })
      .then(({ data }) => {
        if (!data.length) {
          setNoTuning(true);
        } else {
          const ogbhp = parseInt(data[0].originalbhp);
          const ogtq = parseInt(data[0].originaltorque);
          const tunedbhp = parseInt(data[0].tunedbhp);
          const tunedtq = parseInt(data[0].tunedtorque);
          const bhpincrease = parseInt(data[0].bhpincrease);
          const torqueincrease = parseInt(data[0].torqueincrease);

          setTuningOptions([
            {
              type: "power",
              ...data[0],
              mpg: vehicleData.fuel === "Diesel" ? "+5-20%" : "+0-5%",
            },
            {
              type: "combined",
              originalbhp: ogbhp,
              originaltorque: ogtq,
              tunedbhp: Math.round(ogbhp + (tunedbhp - ogbhp) * 0.7),
              tunedtorque: Math.round(ogtq + (tunedtq - ogtq) * 0.7),
              bhpincrease: Math.round(bhpincrease * 0.7),
              torqueincrease: Math.round(torqueincrease * 0.7),
              mpg: vehicleData.fuel === "Diesel" ? "+10-20%" : "+0-10%",
            },
            {
              type: "economy",
              originalbhp: ogbhp,
              originaltorque: ogtq,
              tunedbhp: Math.round(ogbhp + (tunedbhp - ogbhp) * 0.4),
              tunedtorque: Math.round(ogtq + (tunedtq - ogtq) * 0.4),
              bhpincrease: Math.round(bhpincrease * 0.4),
              torqueincrease: Math.round(torqueincrease * 0.4),
              mpg: vehicleData.fuel === "Diesel" ? "+15-25%" : "+5-15%",
            },
          ]);

          setOptionSelected(0);
          setLoading(false);
        }
      });
  }, [vehicleData]);

  const onFinish = () => {
    setVehicleData({
      ...vehicleData,
      noTuning,
      tuningOption: !noTuning ? tuningOptions[optionSelected] : undefined,
    });

    return toNextStep();
  };

  return (
    <div className="widget-steps">
      <div className="tuning-options">
        {loading ? (
          <div className="loading-tuning-options">
            <div className="spinning-wrench">
              <Wrench />
            </div>
            <p>Fetching tuning options...</p>
          </div>
        ) : (
          <div>
            <h3>Tuning Upgrade</h3>

            <div
              className={`tuning-options-list ${
                noTuning ? "options-list-disabled" : ""
              }`}
            >
              {!tuningOptions.length ? (
                <div className="no-upgrades-available">
                  No tuning upgrades available
                </div>
              ) : (
                tuningOptions.map((opt, i) => (
                  <div
                    className={`tuning-option ${opt.type} ${
                      optionSelected === i ? "selected" : ""
                    }`}
                    onClick={() => setOptionSelected(i)}
                    key={i}
                  >
                    {opt.type === "power" && (
                      <div className="recommended-tag" />
                    )}

                    <div className="tuning-option-type">
                      <div className="inner">
                        <div></div>
                      </div>
                    </div>

                    <div className="tuning-option-inner">
                      <div className="tuning-option-column">
                        <div className="column-title">Before</div>
                        <div className="column-data">
                          <div className="column-row">
                            {opt.originalbhp || "N/A"}
                            <span>BHP</span>
                          </div>
                          <div className="column-row">
                            {opt.originaltorque || "N/A"}
                            <span>Torque</span>
                          </div>
                        </div>
                      </div>
                      <div className="tuning-option-column">
                        <div className="column-title">After</div>
                        <div className="column-data">
                          <div className="column-row">
                            {opt.tunedbhp || "N/A"}
                            <span>BHP</span>
                            <span className="mobile-diff">
                              +{opt.bhpincrease || 0}% ({opt.mpg} MPG)
                            </span>
                          </div>
                          <div className="column-row">
                            {opt.tunedtorque || "N/A"}
                            <span>Torque</span>
                            <span className="mobile-diff">
                              +{opt.torqueincrease || 0}% ({opt.mpg} MPG)
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="tuning-option-column diff-column">
                        <div className="column-title">Difference</div>
                        <div className="column-data">
                          <div className="column-row">
                            +{opt.bhpincrease || 0}%
                          </div>
                          <div className="column-row">
                            +{opt.torqueincrease || 0}%
                          </div>
                        </div>
                      </div>
                      <div className="tuning-option-column diff-column">
                        <div className="column-title">MPG</div>
                        <div className="column-data">
                          <div className="column-row">{opt.mpg}</div>
                          <div className="column-row">{opt.mpg}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="no-tuning-required quote-checkbox">
              <input
                id="no-tuning-checkbox"
                type="checkbox"
                checked={noTuning}
                onChange={(e) => setNoTuning(e.target.checked)}
                disabled={!tuningOptions.length}
              />
              <label htmlFor="no-tuning-checkbox">
                <span>No Performance Upgrade Required</span>
              </label>
            </div>

            <button className="prev-step-arrow" onClick={toPreviousStep}>
              <ArrowLeft />
            </button>
            <button className="next-step-arrow" onClick={onFinish}>
              <ArrowRight />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectTuningUpgrade;
