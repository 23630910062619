import React from "react";

const Progress = ({ totalSteps = 0, currentStep = 1 }) => {
  const progressPercentage = (100 / (totalSteps - 1)) * (currentStep - 1) + "%";

  return (
    <div className="widget-progress">
      <div className="widget-progress-bar">
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: progressPercentage }}
          />
          {new Array(totalSteps).fill().map((_, i) => (
            <div
              key={i}
              className={`progress-point ${i >= currentStep ? "inactive" : ""}`}
            >
              {i + 1}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Progress;
