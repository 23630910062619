import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowLeft } from "./../../assets/arrow-left.svg";
import { ReactComponent as ArrowRight } from "./../../assets/arrow-right.svg";

const OPTIONS = [
  {
    name: "DPF Software Delete",
    showIf: ["diesel"],
    checked: false,
    price: "Free",
  },
  {
    name: "EGR Software Delete",
    showIf: ["diesel"],
    checked: false,
    price: "Free",
  },
  {
    name: "DTC (Fault Code) Removal",
    showIf: ["petrol", "diesel"],
    checked: false,
    price: "Free",
  },
  {
    name: "Speed Limiter Activation/Delete",
    showIf: ["petrol", "diesel"],
    checked: false,
    price: "Free",
  },
  {
    name: "Rev Limiter Activation/Delete",
    showIf: ["petrol", "diesel"],
    checked: false,
    price: "Free",
  },
  {
    name: "Popcorn/Hardcut Limiter",
    showIf: ["diesel"],
    checked: false,
    price: "+£60",
  },
  {
    name: "Flaps / Swirl Removal",
    showIf: ["petrol", "diesel"],
    checked: false,
    price: "Free",
  },
  {
    name: "Lambda/O2 Removal",
    showIf: ["petrol", "diesel"],
    checked: false,
    price: "Free",
  },
  { name: "GPF/OPF Delete", showIf: ["petrol"], checked: false, price: "Free" },
  {
    name: "Burbles/Crackle Map",
    showIf: ["petrol"],
    checked: false,
    price: "+£60",
  },
  {
    name: "Full 'Pops and Bangs' Maps",
    showIf: ["petrol"],
    checked: false,
    price: "+£60",
  },
];

const TuningOptions = ({
  vehicleData,
  setVehicleData,
  toPreviousStep,
  toNextStep,
}) => {
  const [checkboxes, setCheckboxes] = useState(OPTIONS);
  const [extraDetails, setExtraDetails] = useState(
    vehicleData.tuningOptionsExtraDetails
  );

  useEffect(() => {
    setCheckboxes((checkboxes) =>
      checkboxes.map((checkbox) => ({
        ...checkbox,
        checked: (vehicleData.tuningOptions || []).includes(checkbox.name),
      }))
    );
  }, [vehicleData.tuningOptions]);

  const shouldOptionShow = (conditions) => {
    const fuel = vehicleData.fuel.toLowerCase();
    const make = vehicleData.make.toLowerCase();
    const year = vehicleData.registrationyear.split("-")[0].toLowerCase();

    for (let cond of conditions) {
      switch (cond) {
        case "petrol":
          if (fuel.includes("petrol")) return true;
          break;
        case "diesel":
          if (fuel.includes("diesel")) return true;
          break;
        case "bmw/mini/mercedes/2016":
          if (["bmw", "mini", "mercedes"].includes(make) && year >= 2016) {
            return true;
          }
          break;
        default:
      }
    }
  };

  const onToggleCheckbox = (index, checked) => {
    setCheckboxes(
      checkboxes.map((cb, i) => (i === index ? { ...cb, checked } : cb))
    );
  };

  const onFinish = () => {
    setVehicleData({
      ...vehicleData,
      tuningOptions: checkboxes
        .filter(({ checked }) => checked)
        .map(({ name }) => name),
      tuningOptionsExtraDetails: extraDetails,
    });

    toNextStep();
  };

  return (
    <>
      <div className="widget-steps">
        <div className="quote-checkboxes">
          <h3>Tuning Options</h3>

          <div className="quote-checkboxes-inner">
            {checkboxes.map(
              ({ name, showIf, checked, price }, i) =>
                shouldOptionShow(showIf) && (
                  <div className="quote-checkbox" key={i}>
                    <input
                      type="checkbox"
                      id={`option-${i}`}
                      checked={checked}
                      onChange={(e) => onToggleCheckbox(i, e.target.checked)}
                    />
                    <label htmlFor={`option-${i}`}>
                      <span>
                        {name} {!vehicleData.noTuning && `(${price})`}
                      </span>
                    </label>
                  </div>
                )
            )}

            <div className="extra-details-textarea">
              <label htmlFor="extra-details">Other details</label>
              <textarea
                id="extra-details"
                value={extraDetails}
                onChange={(e) => setExtraDetails(e.target.value)}
              />
            </div>
          </div>
        </div>

        <button className="prev-step-arrow" onClick={toPreviousStep}>
          <ArrowLeft />
        </button>
        <button className="next-step-arrow" onClick={onFinish}>
          <ArrowRight />
        </button>
      </div>
    </>
  );
};

export default TuningOptions;
